import React from 'react'
import Layout from './Layout'

export default function Test() {
    return (
        <Layout>
            <img src='../images/logo-dti.png'></img>
            <div>Test</div>
            <h2>Uji Coba</h2>
        </Layout>
    )
}
