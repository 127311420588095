import React from 'react'
import Layout from './Layout'

export default function Home() {
    return (
        <Layout>
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-16">
                <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-700 sm:text-6xl animate-fade-right animate-duration-[1100ms] animate-normal">
                        Digital One
                    </h1>
                    <h3 className='text-gray-500 animate-fade-left animate-duration-[1100ms] animate-normal pt-3'>PT Digital Tunggal Indonesia</h3><br />
                    <p className="mt-8 text-lg leading-8 text-gray-600 animate-fade-up animate-duration-[2000ms] animate-ease-out">
                        Welcome to Digital One, your premier destination for cutting-edge technology solutions. Since 2018, we seamlessly integrate software, hardware, and expert consulting services to empower businesses and individuals alike to thrive in the digital age.

                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">

                    </div>
                </div>
            </div>
        </Layout>
    )
}
