

import { Link, useRouteError } from "react-router-dom";
import Layout from "./Layout";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <Layout>
            <div id="ErrorPages" className="mt-10">
                <h1 className=" text-center text-2xl py-10 ">
                    Maaf, Halaman tidak ditemukan!</h1>


                {/* <Link to={'/'} >
                    <img src="./images/logo-dti.png" className="pt-8 items-center px-auto">
                    </img>
                </Link> */}
                <p>
                    {/* <i>{error.statusText || error.message}</i> */}
                </p>
            </div>
        </Layout >
    );
}