import React from 'react'
import Layout from './Layout'

export default function Home() {
  return (
    <Layout>
      <div className="mx-auto max-w-3xl py-12 sm:py-20 lg:py-12">
        <div className="text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-700 sm:text-5xl">
            Our Products and services
          </h2><br />
          {/* Software */}
          <div class="bg-transparent py-8 sm:py-8">
            <div class="mx-auto max-w-6xl px-6 lg:px-8">
              <h2 class="text-center text-lg font-semibold leading-6 text-gray-700">

                At Digital One, we're dedicated to delivering unparalleled excellence in technology solutions. With our dynamic array of offerings, we seamlessly blend software, hardware, services, and expert consultation to empower your success.<br /><br />

                Here's how we elevate your business:<br /><br />

                <b>1. Software Solutions</b><br /> Our cutting-edge software suite is tailored to meet your unique requirements. Whether you seek streamlined workflows, robust analytics, or enhanced security, we deliver intuitive solutions that propel your operations forward.<br /><br />

                <b> 2.Hardware Innovations</b><br /> Explore a world of possibilities with our premium hardware offerings. From state-of-the-art devices to infrastructure essentials, we provide reliable tools that optimize performance and reliability.<br /><br />

                <b>3.Comprehensive Services</b><br />Our commitment extends beyond products. Our dedicated team offers end-to-end support, from implementation to maintenance, ensuring smooth integration and maximum efficiency.<br /><br />

                <b>4.Expert Consultation</b><br /> Leverage our industry expertise to drive strategic decisions. Our seasoned consultants work closely with you to understand your goals, offering tailored insights and actionable recommendations.<br /><br />

                Partner with <b>Digital One</b> and unlock the full potential of your business. Together, we'll navigate the complexities of modern technology and pave the way for your success.<br /><br />

                Reach out today to embark on your journey to excellence!<br />
                <br /><br /><br /><br /><br />
              </h2>


              <div class="mx-auto mt-8 grid max-w-lg grid-cols-4 items-center gap-x-5 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="./images/loga-cc.png" alt="Adobe Creative Cloud" width="140" height="140"></img>
                <img class="col-span-2 max-h-14 w-full object-contain lg:col-span-1" src="./images/logo-ms.png" alt="Microsoft 365" width="140" height="140"></img>
                <img class="col-span-2 max-h-20 w-full object-contain lg:col-span-1" src="./images/logo-fortinet.png" alt="Fortinet" width="140" height="140"></img>
                <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo-sf.png" alt="Salesforce" width="140" height="140"></img>
                <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo-autodesk.png" alt="Autodesk" width="140" height="140"></img>
                <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo_dell.png" alt="Dell" width="140" height="140"></img>
                <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo_lenovo.png" alt="Lenovo" width="140" height="140"></img>
                <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo_sketchup.png" alt="Sketch up" width="140" height="140"></img>
                <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo_gik.png" alt="GIK" width="140" height="140"></img>
                <img class="col-span-2 max-h-16 w-full object-contain lg:col-span-1" src="./images/logo_mairesto.png" alt="maiResto" width="140" height="140"></img>


                {/* <img class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg" alt="SavvyCal" width="158" height="48"></img>
                <img class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg" alt="Statamic" width="158" height="48"></img> */}
              </div>
            </div>

          </div>



          <div className="mt-10 flex items-center justify-center gap-x-6">

          </div>
        </div>
      </div>
    </Layout >
  )
}
