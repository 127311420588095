import React from 'react'
import Layout from './Layout'

export default function Sales() {
    return (
        <Layout>

            <div>Ini Halaman Sales</div><br />
            <h3>List Customer: </h3>
        </Layout>
    )
}
