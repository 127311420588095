import React from 'react'
import Layout from './Layout'


export default function Home() {
    return (
        <Layout>
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-24">
                <div className="text-center items-center">
                    <h3 className="text-4xl mx-auto font-bold tracking-tight text-gray-700 sm:text-4xl">
                        Contact us
                    </h3><br />
                    <h3>Main Office</h3><br />
                    <p className="mt-8 text-lg leading-8 text-gray-600 items-center py-auto display:block; mx-auto" >
                        <img class=" lg:col-span-1 mx-auto animate-jump-in animate-ease-out object-scale-down h-48 w-96 " src="./images/office-building.png" alt="Office" width="10" height="10"></img>

                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.88534424426905!2d106.67522382033661!3d-6.241906368229009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fbcabc6e7201%3A0x155337ba54c7b2cc!2sKantor%20Konsultan%20Pajak%20Kriswandi%2C%20Se%2C%20Bkp%20%26%20Rekan!5e0!3m2!1sen!2sid!4v1709556669870!5m2!1sen!2sid" width={300} height={200} style={{ border: 0 }} item-align="center" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='mx-auto' /> */}
                        <br />
                        <div className='animate-jump-in animate-ease-out text-base'>
                            Jl Boulevard Graha Raya, Kebayoran Utama<br />
                            Komplek Ruko Cendana Loka, Blk. G1 No.12,<br />
                            Paku Jaya, Kec. Serpong Utara, Kota Tangerang Selatan, Banten 15324
                            <br /><br /></div>
                        Phone: 021-53131930<br></br><br />
                        <a href="https://wa.me/+6281287922087" target="_blank">
                            <img className="mx-auto" src="./images/logo_wa.png" alt=""></img>
                        </a><br />


                        <font className="font-bold">Email:</font><br />
                        sales@digitaltunggal.com<br />
                        sales@digitaltunggalindonesia.net
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6 text-green-700">

                    </div>
                </div>
            </div>
        </Layout>
    )
}
